<script setup lang="ts">
import { useRouter } from 'vue-router';
import { FhButton, FhTitle } from '@fareharbor-com/beacon-vue';

const router = useRouter();

function redirectToHome() {
  router.push('/');
}

function contactSupport() {
  const supportUrl = 'https://fareharbor.com/help/submit/';
  window.open(supportUrl, '_blank', 'noopener,noreferrer');
}
</script>

<template>
  <main class="flex flex-col items-center justify-center px-4">
    <FhTitle>
      404 - Item Not Found
      <template #subtitle>
        Sorry about that. You may have followed an unknown item link. If you
        need help, please contact Support.
      </template>
    </FhTitle>

    <div class="flex flex-col sm:flex-row gap-4 py-6 w-full sm:w-auto">
      <FhButton
        size="lg"
        @click="redirectToHome"
        aria-label="Return to Home"
        class="w-full sm:w-auto"
      >
        Return To Home
      </FhButton>

      <FhButton
        size="lg"
        @click="contactSupport"
        aria-label="Contact Support"
        variant="secondary"
        class="w-full sm:w-auto"
      >
        Contact Support
      </FhButton>
    </div>
  </main>
</template>
