<script setup lang="ts">
import { h, onMounted, ref, watch } from 'vue';
import { RouterLink } from 'vue-router';
import { FhButton, FhIcon, FhLink } from '@fareharbor-com/beacon-vue';
import { location, search } from '@fareharbor-com/beacon-vue/icons';
import { storeToRefs } from 'pinia';
import SearchBar from '@/components/SearchBar/SearchBar.vue';
import { useLocationStore } from '@/stores/locations';
import { useSearchStore } from '@/stores/search';
import { useAuthStore } from '@/stores/useAuthStore';
import LocationSearchBar from './SearchBar/LocationSearchBar.vue';

const locationIcon = () => h(FhIcon, { icon: location });
const searchIcon = () => h(FhIcon, { icon: search });

const authStore = useAuthStore();

const { isAuthenticated } = storeToRefs(authStore);

const searchStore = useSearchStore();

const locationStore = useLocationStore();

// NOTE: To forcefully re-render the search bars the technique explained here is used:
// https://stackoverflow.com/questions/32106155/can-you-force-vue-js-to-reload-re-render
const searchBarKey = ref(0);

const doLogout = () => {
  authStore.logout();
};

function handleSubmitLocationSearch(text: string) {
  if (searchStore.locationSearchText === text) {
    searchStore.locationSearchChanged = false;
    return;
  }
  console.debug('[MainHeader] Search location:', text);
  searchStore.locationSearchText = text;
  searchStore.locationSearchChanged = true;
}

function handleSubmitCompanyItemSearch(text: string) {
  if (searchStore.companyItemSearchText === text) {
    searchStore.companyItemSearchChanged = false;
    return;
  }
  console.debug('[MainHeader] Search company / item:', text);
  searchStore.companyItemSearchText = text;
  searchStore.companyItemSearchChanged = true;
}

function handleClearLocationSearch() {
  console.debug('[MainHeader] Clearing location search');
  searchStore.locationSearchText = '';
  searchStore.locationSearchChanged = true;
}

function handleClearCompanyItemSearch() {
  console.debug('[MainHeader] Clearing company & item search');
  searchStore.companyItemSearchText = '';
  searchStore.companyItemSearchChanged = true;
}

function showLocationSuggestions() {
  const suggestions = locationStore.getSuggestions(searchStore.locationSearch);
  console.debug('[MainHeader] Show location suggestions: ', suggestions);
}

function handleSearchTextChange() {
  searchBarKey.value += 1;
  showLocationSuggestions();
}

// handleSearch is called when locationSearchText/companyItemSearchText is updated in searchStore.
searchStore.$subscribe(handleSearchTextChange);

watch(isAuthenticated, (newState) => {
  if (newState) {
    console.debug('[MainHeader][isAuthenticated-watch] Locations fetched');
    locationStore.fetchLocations();
  }
});

onMounted(() => {
  if (isAuthenticated.value) {
    console.debug('[MainHeader] Locations fetched');
    locationStore.fetchLocations();
  }
});
</script>

<template>
  <nav class="bg-white dark:bg-gray-900 border-b border-b-gray-300">
    <div class="flex flex-wrap justify-between items-center p-3">
      <RouterLink
        class="flex items-center space-x-3 rtl:space-x-reverse py-2"
        :to="{ name: 'item-list' }"
      >
        <img
          src="@/assets/images/logo.png"
          class="h-8 w-auto sm:h-10"
          alt="Logo"
          data-test-id="logo-icon"
        />
        <p class="text-xl" data-test-id="header-title">Marketplace</p>
      </RouterLink>

      <div
        v-if="$route.name === 'item-list'"
        class="flex flex-col sm:flex-row sm:space-x-5 space-y-3 sm:space-y-0 w-full sm:w-auto"
        :key="searchBarKey"
      >
        <LocationSearchBar
          class="flex"
          :initialText="searchStore.locationSearchText"
          :placeholder="'Search by city, state, or country'"
          :icon="locationIcon"
          @submitSearch="handleSubmitLocationSearch"
          @clearSearch="handleClearLocationSearch"
        />
        <SearchBar
          class="flex"
          :initialText="searchStore.companyItemSearchText"
          :placeholder="'Search by company, shortname, item, or item ID'"
          :icon="searchIcon"
          @submitSearch="handleSubmitCompanyItemSearch"
          @clearSearch="handleClearCompanyItemSearch"
        />
      </div>

      <div
        class="flex items-center space-x-3 sm:space-x-6 rtl:space-x-reverse mt-3 sm:mt-0"
      >
        <FhButton
          variant="secondary"
          v-if="isAuthenticated"
          href="/?isBookmarked=true"
          data-test-id="user-bookmark-items-link"
        >
          <!-- Todo: Remove in [AF-1355]: Replace with beacon icons -->
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_5168_1545)">
              <path
                d="M12.5 8.3335V17.5002L8.33329 15.0002L4.16663 17.5002V8.3335C4.16663 7.67045 4.43002 7.03457 4.89886 6.56573C5.3677 6.09689 6.00358 5.8335 6.66663 5.8335H9.99996C10.663 5.8335 11.2989 6.09689 11.7677 6.56573C12.2366 7.03457 12.5 7.67045 12.5 8.3335Z"
                stroke="#313C47"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M9.16663 2.5H13.3333C13.9963 2.5 14.6322 2.76339 15.1011 3.23223C15.5699 3.70107 15.8333 4.33696 15.8333 5V14.1667"
                stroke="#313C47"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_5168_1545">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>

          Bookmarks
        </FhButton>
        <FhLink
          v-if="isAuthenticated"
          class="!no-underline"
          variant="primary"
          :href="'https://docs.google.com/forms/d/1AEHIjw1kETN5cOi1pnFR-3xlwxEU9X0rqaZnFGus9Is/edit'"
          target="_blank"
          data-test-id="user-feedback-link"
        >
          Feedback
        </FhLink>
        <FhButton
          v-if="isAuthenticated"
          variant="tertiary"
          @click="doLogout"
          data-test-id="logout-button"
        >
          Log out
        </FhButton>
      </div>
    </div>
  </nav>
</template>

<style scoped lang="scss">
.auth-button {
  min-width: 2cm;

  svg {
    max-height: 1cm;
  }
}
</style>
