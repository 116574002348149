import { type Ref, ref } from 'vue';
import { defineStore } from 'pinia';
import { type GetItemsOptions, MAX_PAGE_SIZE } from '@/common/api';
import { getCurrencies } from '@/common/api/getCurrencies';
import type { Currency, CurrencyListPayload, CurrencyPayload } from '@/types';

async function transformSingleCurrencyData(
  currencyPayload: CurrencyPayload,
): Promise<Currency> {
  return {
    name: currencyPayload.name,
    code: currencyPayload.code,
    symbol: currencyPayload.symbol,
    relatedItemsCount: currencyPayload.related_items_count,
  };
}

const transformCurrenciesData = async (
  data: CurrencyListPayload,
): Promise<Array<Currency>> =>
  Promise.all(
    data.results.map(async (CurrencyPayload) =>
      transformSingleCurrencyData(CurrencyPayload),
    ),
  );

export const useCurrenciesStore = defineStore('currenciesStore', () => {
  const currencies: Ref<Array<Currency>> = ref([]);
  const isReady: Ref<boolean> = ref(false);
  const isUpdated: Ref<boolean> = ref(false);

  /**
   * Fetch all pages of currencies in batches of MAX_PAGE_SIZE
   */
  async function loadAllCurrencies(options: GetItemsOptions = {}) {
    const MAX_REQUESTS_COUNT = 10;

    let newCurrencies: Array<Currency> = [];
    isUpdated.value = false;
    if (currencies.value.length === 0) {
      isReady.value = false;
    }

    let page = 1;
    let thereIsNext = true;
    while (thereIsNext && page <= MAX_REQUESTS_COUNT) {
      try {
        const fullOptions = { ...options, page, pageSize: MAX_PAGE_SIZE };
        // eslint-disable-next-line no-await-in-loop
        const { status, data } = await getCurrencies(fullOptions);

        if (status !== 200 || !data) {
          throw new Error('Failed to load Currencies');
        }

        newCurrencies = [
          ...newCurrencies,
          ...(await transformCurrenciesData(data)), // eslint-disable-line no-await-in-loop
        ];

        thereIsNext = data.next !== null;
        if (!thereIsNext) {
          console.debug(
            '[stores][currencies] No more pages to load',
            `current: ${page}`,
          );
        } // no need to continue if no pages left
      } catch (e: unknown) {
        console.debug(`Unable to fetch currencies: ${e}`);
        break;
      }
      page += 1;
    }

    // The counts might be updated, even when the list is the same.
    if (newCurrencies.length === currencies.value.length) {
      isUpdated.value = true;
    }
    currencies.value = newCurrencies;
    isReady.value = true;
  }

  return { currencies, isReady, isUpdated, loadAllCurrencies };
});
