import { config } from '@/configs';
import { InventoryEnvironment } from '@/types/InventoryEnvironment';

const environmentsWithSentryEnabled = [
  InventoryEnvironment.PRODUCTION,
  InventoryEnvironment.STAGING,
  // Uncomment this line to run Sentry for local errors
  // InventoryEnvironment.LOCAL,
];

export const isSentryEnabledOnCurrentEnvironment =
  environmentsWithSentryEnabled.includes(config.ENVIRONMENT);
