import type { App } from 'vue';
import {
  browserTracingIntegration,
  captureConsoleIntegration,
  init,
  replayIntegration,
} from '@sentry/vue';
import { config } from '@/configs';
import router from '@/router';
import { isSentryEnabledOnCurrentEnvironment } from './isSentryEnabledOnCurrentEnvironment';

const ignoreErrors: string[] = [
  // This error is the most common alert we see on our Sentry dashboards
  // We were unable to reproduce it nor did we associate with any known issues
  "Cannot read properties of undefined (reading '_vts')",
  // If we get a status of 409, this means that a reload is required.
  '[status=409]',
];

export const initializeSentry = (app: App) => {
  if (!config.SENTRY_DSN) {
    return;
  }
  if (!isSentryEnabledOnCurrentEnvironment) {
    return;
  }
  init({
    app,
    dsn: config.SENTRY_DSN,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      browserTracingIntegration({ router }),
      captureConsoleIntegration({ levels: ['error'] }),
      replayIntegration(),
    ],
    environment: config.ENVIRONMENT,
    release: config.COMMIT_ID,
    tracesSampleRate: config.SENTRY_SAMPLING_RATE,
    ignoreErrors,
  });
};
