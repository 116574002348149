<script setup lang="ts">
import { type Ref, ref, toRefs } from 'vue';
import type { FlexContent, FlexDirection, FlexItems, Gap } from './types';
import { useFlexboxClasses } from './useFlexboxClasses';

export interface BaseFlexboxProps {
  direction?: FlexDirection;
  gap?: Gap;
  justifyContent?: FlexContent;
  alignItems?: FlexItems;
  alignSelf?: FlexItems | 'auto';
  grow?: '0' | '1';
  display?: 'flex' | 'inline-flex';
  dataTestId?: string;
}

const props = withDefaults(defineProps<BaseFlexboxProps>(), {
  direction: 'row',
  display: 'flex',
  grow: '0',
  gap: '0',
  justifyContent: 'normal',
  alignItems: 'stretch',
  alignSelf: 'auto',
});

const { flexboxClasses } = useFlexboxClasses(toRefs(props));

const containerRef: Ref<HTMLElement | undefined> = ref();

defineExpose({ containerRef });
</script>

<template>
  <div
    :class="flexboxClasses"
    :data-test-id="dataTestId || 'flexbox'"
    ref="containerRef"
  >
    <slot></slot>
  </div>
</template>
