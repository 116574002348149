export interface ParsePositiveIntOptions {
  value?: any;
  fallback: number;
  min?: number;
  max?: number;
}

export function parsePositiveInt({
  value,
  fallback,
  min = 1,
  max = Infinity,
}: ParsePositiveIntOptions): number {
  const enforcedMin = Math.max(min, 1);

  const parsedNum = Number(value);

  if (
    !Number.isInteger(parsedNum) ||
    parsedNum < enforcedMin ||
    parsedNum > max
  ) {
    return fallback;
  }

  return parsedNum;
}
