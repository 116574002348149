<script setup lang="ts">
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { FhLink } from '@fareharbor-com/beacon-vue';
import LoginForm from '@/components/LoginForm/LoginForm.vue';
import { useAuthStore } from '@/stores/useAuthStore';

const router = useRouter();

const authStore = useAuthStore();

onMounted(async () => {
  if (!authStore.isAuthenticated) {
    router.push({ name: 'login' });
  }
});

function openMailClient() {
  window.location.href = 'mailto:support@fareharbor.com';
}
</script>
<template>
  <div class="flex flex-col" style="height: calc(100vh - 130px)">
    <div
      id="authentication-modal"
      tabindex="-1"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-title"
      data-test-id="login-modal"
      class="fixed inset-0 z-50 flex justify-center items-center w-full h-screen backdrop-blur-sm"
    >
      <div
        class="relative w-full max-w-md p-6 bg-white dark:bg-gray-800 rounded-lg shadow-lg border border-gray-300"
      >
        <div
          class="flex items-center justify-between p-4 border-b border-gray-200 dark:border-gray-600 rounded-t"
        >
          <h3
            id="modal-title"
            class="text-xl font-semibold text-gray-900 dark:text-white"
          >
            Sign in to your inventory
          </h3>
        </div>

        <div class="p-4">
          <LoginForm />
          <div
            class="text-sm font-medium text-gray-500 dark:text-gray-300 mt-2"
          >
            Not registered?
            <FhLink
              variant="primary"
              @click="openMailClient"
              data-test-id="contact-support-link"
              href="#"
            >
              Contact support
            </FhLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
