import { authorizationHeaders } from '@/common/authorizationHeaders';
import { API_URLS } from '@/configs';
import { type GenericResponse } from '.';
import { CREATED } from '../constants/status';

interface BookmarkPayload {
  id: number;
  marketplace_user_id: number;
  item_id: number;
  created_at: string;
  modified_at: string;
}

type AddItemToBookmarksResponse = GenericResponse<BookmarkPayload>;

export const addItemToBookmarks = async (
  itemId: number,
): Promise<AddItemToBookmarksResponse> => {
  const url = `${API_URLS.BASE}/item-bookmarks/`;

  const payload = {
    item_id: itemId,
  };

  const response: Response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...authorizationHeaders(),
    },
    body: JSON.stringify(payload),
  });

  let error: Error | null = null;
  let data: BookmarkPayload | undefined;

  if (!response.ok) {
    error = new Error(`HTTP error! status: ${response.status}`);
  } else if (response.status === CREATED) {
    data = await response.json();
  }

  return {
    status: response.status,
    data,
    error,
  };
};
