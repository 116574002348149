<script setup lang="ts">
import {
  type ComputedRef,
  type Ref,
  computed,
  onMounted,
  ref,
  watch,
} from 'vue';
import { FhIcon, FhSpinner } from '@fareharbor-com/beacon-vue';
import { search } from '@fareharbor-com/beacon-vue/icons';
import { storeToRefs } from 'pinia';
import CollapsibleSection from '@/components/CollapsibleSection.vue';
import AppliedFilterBadge from '@/components/itemList/AppliedFilterBadge.vue';
import { ITEM_LIST_FILTERS_FEATURE_FLAGS } from '@/components/itemList/features';
import {
  type CurrencyOption,
  FilterKind,
  type FiltersChangedEventData,
  type ItemTypeOption,
  type OfferedSinceOption,
  OfferedSinceOptionVariant,
} from '@/components/itemList/types';
import DeskCheckbox from '@/components/shared/DeskCheckbox/DeskCheckbox.vue';
import BaseFlexbox from '@/components/ui/BaseFlexbox/BaseFlexbox.vue';
import { useCurrenciesStore } from '@/stores/currencies';
import { useItemFiltersStore } from '@/stores/itemFilters';
import { useTagsStore } from '@/stores/tags';

const tagsStore = useTagsStore();
const currenciesStore = useCurrenciesStore();
const itemFiltersStore = useItemFiltersStore();

const OFFERED_SINCE_OPTIONS: Array<OfferedSinceOption> = [
  {
    optionName: 'No preference',
    id: OfferedSinceOptionVariant.NO_PREFERENCE,
  },
  {
    optionName: 'Last 7 days',
    id: OfferedSinceOptionVariant.LAST_7_DAYS,
  },
  {
    optionName: 'Last 30 days',
    id: OfferedSinceOptionVariant.LAST_30_DAYS,
  },
  {
    optionName: 'Last 90 days',
    id: OfferedSinceOptionVariant.LAST_90_DAYS,
  },
];

// references
const itemTypeSearchText: Ref<string> = ref('');
const itemTypeOptions: Ref<Array<ItemTypeOption>> = ref([]);
const currencyOptions: Ref<Array<CurrencyOption>> = ref([]);

const { isReady: areTagsReady, isUpdated: areTagsUpdated } =
  storeToRefs(tagsStore);
const { isReady: areCurrenciesReady, isUpdated: areCurrenciesUpdated } =
  storeToRefs(currenciesStore);

// computed properties
const filteredItemTypeOptions: ComputedRef<Array<ItemTypeOption>> = computed(
  () =>
    [
      ...itemTypeOptions.value.filter((value) =>
        value.itemTypeName.toLowerCase().includes(itemTypeSearchText.value),
      ),
    ].sort((a, b) => {
      if (a.checked === b.checked) return 0;

      if (a.checked && !b.checked) return -1;

      return 1;
    }),
);

const isItemTypeFilterApplied: ComputedRef<boolean> = computed(
  () => itemFiltersStore.tags.size > 0,
);

const isOfferedSinceFilterApplied: ComputedRef<boolean> = computed(
  () => itemFiltersStore.offeredSince !== null,
);

const isCurrencyFilterApplied: ComputedRef<boolean> = computed(
  () => itemFiltersStore.currencies.size > 0,
);
const isIncludePhotosApplied: ComputedRef<boolean> = computed(() =>
  // check all the other filters that are enabled
  [itemFiltersStore.includePhotos].reduce(
    (previous, current) => previous || current,
  ),
);

const appliedFilters: ComputedRef<
  { text: string; isApplied: boolean; kind: FilterKind }[]
> = computed(() => [
  {
    text: 'Item type',
    isApplied: isItemTypeFilterApplied.value,
    kind: FilterKind.ITEM_TYPE,
  },
  {
    text: 'Offered since',
    isApplied: isOfferedSinceFilterApplied.value,
    kind: FilterKind.OFFERED_SINCE,
  },
  {
    text: 'Currency',
    isApplied: isCurrencyFilterApplied.value,
    kind: FilterKind.CURRENCY,
  },
  {
    text: 'Include photos',
    isApplied: isIncludePhotosApplied.value,
    kind: FilterKind.INCLUDE_PHOTOS,
  },
]);

const anyFiltersApplied: ComputedRef<boolean> = computed(() =>
  appliedFilters.value
    .map((appliedFilterEntry) => appliedFilterEntry.isApplied)
    .reduce((previous, current) => previous || current),
);

// emits

const emit = defineEmits<{
  filtersChanged: [FiltersChangedEventData];
}>();

// event handlers
function handleItemTypeOptionChange(itemTypeOption: ItemTypeOption) {
  const queryItemTypeOptionShortnames: Set<string> = itemFiltersStore.tags;

  if (itemTypeOption.checked) {
    queryItemTypeOptionShortnames.add(itemTypeOption.itemTypeShortName);
  } else {
    queryItemTypeOptionShortnames.delete(itemTypeOption.itemTypeShortName);
  }
  itemFiltersStore.tags = queryItemTypeOptionShortnames;

  console.debug(
    `Item type filter option changed: ${itemTypeOption.itemTypeName}: ${itemTypeOption.checked}`,
  );

  emit('filtersChanged', { shouldConfirm: true });
}

function handleCurrencyOptionChange(currencyOption: CurrencyOption) {
  const queryCurrencies: Set<string> =
    itemFiltersStore.currencies as Set<string>;

  if (currencyOption.checked) {
    queryCurrencies.add(currencyOption.currency);
  } else {
    queryCurrencies.delete(currencyOption.currency);
  }
  itemFiltersStore.currencies = queryCurrencies;

  emit('filtersChanged', { shouldConfirm: true });
}

function cancelFilter(kind: FilterKind) {
  console.debug('[filters] Cancel filter: ', kind);

  switch (kind) {
    case FilterKind.ITEM_TYPE:
      itemFiltersStore.tags.clear();
      itemTypeOptions.value.forEach((option: ItemTypeOption) => {
        // eslint-disable-next-line no-param-reassign
        option.checked = false;
      });
      break;

    case FilterKind.OFFERED_SINCE:
      itemFiltersStore.offeredSinceVariant =
        OfferedSinceOptionVariant.NO_PREFERENCE;
      break;

    case FilterKind.CURRENCY:
      itemFiltersStore.currencies.clear();
      currencyOptions.value.forEach((option: CurrencyOption) => {
        // eslint-disable-next-line no-param-reassign
        option.checked = false;
      });
      break;

    case FilterKind.INCLUDE_PHOTOS:
      itemFiltersStore.includePhotos = false;
      break;

    default:
      throw Error(
        `[filters] Filter cancellation not handled for kind: ${kind}`,
      );
  }

  emit('filtersChanged', { shouldConfirm: true });
}

function onIncludePhotosFilterChange(newValue: boolean) {
  itemFiltersStore.includePhotos = newValue;
  emit('filtersChanged', { shouldConfirm: true });
}

// helper functions
function makeIdForItemTypeOption(itemTypeOption: ItemTypeOption) {
  return `item-type-option-checkbox-${itemTypeOption.itemTypeShortName}`;
}

function makeIdForOfferedSinceOption(offeredSinceOption: OfferedSinceOption) {
  return `offered-since-option-checkbox-${offeredSinceOption.id}`;
}

function populateItemTypeOptions() {
  const queryItemTypes = itemFiltersStore.tags;
  itemTypeOptions.value = tagsStore.tags.map(
    (tag): ItemTypeOption => ({
      itemTypeName: tag.name,
      itemTypeShortName: tag.shortname,
      relatedItemsCount: tag.relatedItemsCount,
      checked: queryItemTypes.has(tag.shortname),
    }),
  );
}

function populateCurrencyOptions() {
  const queryCurrencies = itemFiltersStore.currencies;
  currencyOptions.value = currenciesStore.currencies.map(
    (currency): CurrencyOption => ({
      currency: currency.code,
      checked: queryCurrencies.has(currency.code),
      relatedItemsCount: currency.relatedItemsCount,
    }),
  );
}

// watchers
watch(areTagsReady, (newState) => {
  if (newState) {
    populateItemTypeOptions();
  }
});

watch(areTagsUpdated, (newState) => {
  if (newState) {
    populateItemTypeOptions();
  }
});

watch(areCurrenciesReady, (newState) => {
  if (newState) {
    populateCurrencyOptions();
  }
});

watch(areCurrenciesUpdated, (newState) => {
  if (newState) {
    populateCurrencyOptions();
  }
});

function handleItemFiltersStoreChange() {
  if (areTagsReady.value) {
    populateItemTypeOptions();
  }
  if (areCurrenciesReady.value) {
    populateCurrencyOptions();
  }
}

// handleItemFiltersStoreChange is called when something in itemFiltersStore changes.
itemFiltersStore.$subscribe(handleItemFiltersStoreChange);

onMounted(() => {
  if (areTagsReady.value) {
    populateItemTypeOptions();
  }

  if (areCurrenciesReady.value) {
    populateCurrencyOptions();
  }
});
</script>

<template>
  <BaseFlexbox direction="column">
    <!-- Applied filers -->
    <CollapsibleSection
      headerText="Applied filters"
      :isInitiallyExpanded="true"
      v-if="anyFiltersApplied"
    >
      <ul v-show="anyFiltersApplied">
        <li
          v-for="appliedFilter in appliedFilters"
          :key="appliedFilter.text"
          class="mb-2"
          v-show="appliedFilter.isApplied"
        >
          <BaseFlexbox justifyContent="start">
            <AppliedFilterBadge
              :text="appliedFilter.text"
              @close="cancelFilter(appliedFilter.kind)"
            />
          </BaseFlexbox>
        </li>
      </ul>
    </CollapsibleSection>

    <!-- Item type filter -->
    <CollapsibleSection
      headerText="Item type"
      :isInitiallyExpanded="true"
      v-if="ITEM_LIST_FILTERS_FEATURE_FLAGS.ITEM_TYPE_FILTER"
    >
      <BaseFlexbox direction="column">
        <!-- Item type search -->
        <div class="mb-4 pt-0">
          <div class="relative">
            <div
              class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none"
            >
              <FhIcon :icon="search" />
            </div>
            <input
              type="search"
              id="default-search"
              class="block w-full p-3 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search"
              required
              v-model="itemTypeSearchText"
              :disabled="!areTagsReady"
            />
          </div>
        </div>
        <div class="flex justify-center">
          <FhSpinner class="mt-4 min-h-10" v-show="!areTagsReady" />
        </div>

        <!-- Available item types -->
        <ul
          class="pl-1 overflow-y-scroll scrollbar scrollbar-thumb-gray-300 scrollbar-track-transparent max-h-72"
          data-test-id="item-type-filter"
        >
          <li
            v-for="itemTypeOption in filteredItemTypeOptions"
            :key="itemTypeOption.itemTypeShortName"
            class="flex items-center mb-2 select-none"
          >
            <input
              :id="makeIdForItemTypeOption(itemTypeOption)"
              type="checkbox"
              class="w-4 h-4 text-blue-700 bg-gray-100 border-gray-300 rounded dark:bg-gray-700 dark:border-gray-600"
              v-model="itemTypeOption.checked"
              @change="handleItemTypeOptionChange(itemTypeOption)"
            />
            <label :for="makeIdForItemTypeOption(itemTypeOption)" class="ms-2">
              {{ itemTypeOption.itemTypeName }}
              <span class="text-slate-400">
                ({{ itemTypeOption.relatedItemsCount }})
              </span>
            </label>
          </li>
        </ul>
      </BaseFlexbox>
    </CollapsibleSection>

    <!-- Offered since filter -->
    <CollapsibleSection
      headerText="Offered since"
      :isInitiallyExpanded="true"
      v-if="ITEM_LIST_FILTERS_FEATURE_FLAGS.OFFERED_SINCE_FILTER"
    >
      <ul class="pl-1" data-test-id="offered-since-filter">
        <li
          v-for="offeredSinceOption in OFFERED_SINCE_OPTIONS"
          :key="offeredSinceOption.id"
        >
          <input
            :id="makeIdForOfferedSinceOption(offeredSinceOption)"
            type="radio"
            v-model="itemFiltersStore.offeredSinceVariant"
            :value="offeredSinceOption.id"
            @change="emit('filtersChanged', { shouldConfirm: false })"
          />
          <label
            :for="makeIdForOfferedSinceOption(offeredSinceOption)"
            class="ms-2"
          >
            {{ offeredSinceOption.optionName }}
          </label>
        </li>
      </ul>
    </CollapsibleSection>

    <!-- Currency filter -->
    <CollapsibleSection
      headerText="Currency"
      :isInitiallyExpanded="true"
      v-if="ITEM_LIST_FILTERS_FEATURE_FLAGS.CURRENCY_FILTER"
    >
      <div class="flex justify-center">
        <FhSpinner class="mt-4 min-h-10" v-show="!areCurrenciesReady" />
      </div>
      <ul class="pl-1" data-test-id="currency-filter-section">
        <li
          v-for="currencyOption in currencyOptions"
          :key="currencyOption.currency"
          class="flex items-center mb-2 select-none"
        >
          <input
            :id="currencyOption.currency"
            type="checkbox"
            class="w-4 h-4 text-blue-700 bg-gray-100 border-gray-300 rounded dark:bg-gray-700 dark:border-gray-600"
            v-model="currencyOption.checked"
            @change="handleCurrencyOptionChange(currencyOption)"
          />
          <label :for="currencyOption.currency" class="ms-2">
            {{ currencyOption.currency.toUpperCase() }}
            <span class="text-slate-400">
              ({{ currencyOption.relatedItemsCount }})
            </span>
          </label>
        </li>
      </ul>
    </CollapsibleSection>

    <div class="border-t pt-3 mt-2">
      <BaseFlexbox
        justify-content="space-between"
        align-items="center"
        class="w-full"
      >
        <DeskCheckbox
          dataTestId="include-photos-section"
          dataTestInputId="include-photos-checkbox"
          label="Include photos"
          v-model="itemFiltersStore.includePhotos"
          @update:model-value="onIncludePhotosFilterChange"
        />
      </BaseFlexbox>
    </div>
  </BaseFlexbox>
</template>
