<script setup lang="ts">
import { ref } from 'vue';
import { FhSpinner } from '@fareharbor-com/beacon-vue';
import { Logger } from '@/common/Logger';
import { addItemToBookmarks } from '@/common/api/addItemToBookmarks';
import { deleteItemFromBookmarks } from '@/common/api/deleteItemFromBookmarks';
import BaseFlexbox from '@/components/ui/BaseFlexbox/BaseFlexbox.vue';
import { useToastStore } from '@/stores/useToastStore';

interface ItemRow {
  itemId: number;
  isBookmarked: boolean;
}

interface ItemListBookmarkProps {
  row: ItemRow;
}

const props = defineProps<ItemListBookmarkProps>();
const { row } = props;
const toastStore = useToastStore();

const isBookmarkLoading = ref(false);
const bookmarkError = ref<Error | null>(null);

// Initialize isBookmarked with the value from props
const isBookmarked = ref(row.isBookmarked);

const toggleBookmark = async () => {
  if (isBookmarkLoading.value) return;

  isBookmarkLoading.value = true;
  bookmarkError.value = null;

  try {
    if (isBookmarked.value) {
      const response = await deleteItemFromBookmarks(row.itemId);
      if (response.error) {
        bookmarkError.value = response.error;
        toastStore.error('Error removing bookmark, please try again.');
        Logger.info('Error removing bookmark:', response.error);
      } else {
        toastStore.success('Bookmark removed successfully.');
        isBookmarked.value = false;
      }
    } else {
      const response = await addItemToBookmarks(row.itemId);

      if (response.error) {
        bookmarkError.value = response.error;
        toastStore.error('Error adding bookmark, please try again.');
        Logger.info('Error adding bookmark:', response.error);
      } else {
        toastStore.success('Bookmark added successfully.');
        isBookmarked.value = true;
      }
    }
  } catch (error: unknown) {
    Logger.error(error);

    if (error instanceof Error) {
      bookmarkError.value = error;
      toastStore.error(`Unexpected error: ${error.message}`);
    } else {
      bookmarkError.value = new Error(
        'An unknown error occurred while toggling the bookmark.',
      );
      toastStore.error(
        'An unknown error occurred while toggling the bookmark.',
      );
    }
  } finally {
    isBookmarkLoading.value = false;
  }
};
</script>

<template>
  <BaseFlexbox
    class="px-1 py-3"
    direction="column"
    alignItems="center"
    @click.stop.prevent="toggleBookmark"
  >
    <!-- Todo: Remove in [AF-1355]: Replace with beacon icons -->
    <template v-if="isBookmarkLoading">
      <FhSpinner size="sm" />
    </template>
    <template v-else-if="isBookmarked">
      <!-- SVG icon for bookmarked state -->
      <svg
        width="12"
        height="18"
        viewBox="0 0 12 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.66675 0.666504C8.77182 0.666504 9.83162 1.10549 10.613 1.88689C11.3944 2.66829 11.8334 3.7281 11.8334 4.83317V16.4998C11.8334 16.6507 11.7925 16.7988 11.715 16.9282C11.6375 17.0576 11.5262 17.1636 11.3932 17.2347C11.2602 17.3059 11.1103 17.3396 10.9597 17.3323C10.809 17.325 10.6631 17.2769 10.5376 17.1932L6.00008 14.1682L1.46342 17.1932C1.34449 17.2728 1.20708 17.3204 1.06442 17.3315C0.921749 17.3425 0.778635 17.3167 0.648857 17.2564C0.519079 17.1961 0.407011 17.1034 0.323447 16.9873C0.239884 16.8711 0.18764 16.7354 0.171748 16.5932L0.166748 16.4998V4.83317C0.166748 3.7281 0.605735 2.66829 1.38714 1.88689C2.16854 1.10549 3.22835 0.666504 4.33342 0.666504H7.66675Z"
          fill="#313C47"
        />
      </svg>
    </template>
    <template v-else>
      <!-- SVG icon for unbookmarked state -->
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_5168_1687)">
          <path
            d="M15 10.8333V17.5L10 14.1667L5 17.5V5.83333C5 4.94928 5.35119 4.10143 5.97631 3.47631C6.60143 2.85119 7.44928 2.5 8.33333 2.5H10"
            stroke="#313C47"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12.3334 4.8335H17.3334"
            stroke="#313C47"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M14.8334 2.3335V7.3335"
            stroke="#313C47"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_5168_1687">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </template>
  </BaseFlexbox>
</template>
