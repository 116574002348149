import { formatISO } from 'date-fns';
import { authorizationHeaders } from '@/common/authorizationHeaders';
import { SortByOption } from '@/common/constants/items';
import { API_URLS } from '@/configs';
import type { ItemListPayload, ItemRatingPayload } from '@/types';
import {
  type GenericResponse,
  type GetItemsOptions,
  type GetItemsURLOptions,
  fetchData,
  redirectToLoginIfUnauthorized,
} from '.';

export type GetItemsResponse = GenericResponse<ItemListPayload>;
export type GetItemsCSVResponse = GenericResponse<Blob>;
export type GetItemRatingResponse = GenericResponse<ItemRatingPayload>;

export const OrderingOption: Record<number, string> = {
  [SortByOption.OLDEST]: 'created_at',
  [SortByOption.NEWEST]: '-created_at',
  [SortByOption.ITEM_NAME_ASCENDING]: 'normalized_name',
  [SortByOption.ITEM_NAME_DESCENDING]: '-normalized_name',
  [SortByOption.COMPANY_SHORTNAME_ASCENDING]: 'company__shortname',
  [SortByOption.COMPANY_SHORTNAME_DESCENDING]: '-company__shortname',
  [SortByOption.CITY_NAME_ASCENDING]: 'location_city',
  [SortByOption.CITY_NAME_DESCENDING]: '-location_city',
  [SortByOption.QUALITY_ASCENDING]: 'total_quality_score',
  [SortByOption.QUALITY_DESCENDING]: '-total_quality_score',

  // TODO: add when available
  // [SortByOption.RATING]: '',
};

export const makeURLWithItemFilters = (
  url: URL,
  options: GetItemsURLOptions,
): URL => {
  if (options.page !== undefined && options.page !== null)
    url.searchParams.set('page', options.page.toString());

  if (
    options.pageSize !== undefined &&
    options.pageSize !== null &&
    options.pageSize > 0
  )
    url.searchParams.set('page_size', options.pageSize.toString());

  if (options.sortBy !== undefined && options.sortBy !== null) {
    url.searchParams.set('ordering', OrderingOption[options.sortBy]);
  }

  options.currencies?.forEach((currency) =>
    url.searchParams.append('currency', currency),
  );

  options.tags?.forEach((tag) => url.searchParams.append('tag', tag));

  if (options.offeredSince !== undefined && options.offeredSince !== null) {
    url.searchParams.set(
      'offered_since',
      formatISO(options.offeredSince, { representation: 'date' }),
    );
  }

  if (
    options.includePhotos !== undefined &&
    options.includePhotos !== null &&
    options.includePhotos
  ) {
    url.searchParams.set('image_count__gt', '0');
  }

  if (options.isBookmarked === true) {
    url.searchParams.set('is_bookmarked', 'true');
  }

  if (options.searchLocation !== undefined && options.searchLocation !== null) {
    url.searchParams.set('search_location', options.searchLocation);
  }

  if (options.search !== undefined && options.search !== null) {
    url.searchParams.set('search', options.search);
  }

  const csvFormat = options.csv ?? false;
  if (csvFormat) {
    url.searchParams.set('format', 'csv');
  }

  return url;
};

export const getItems = async (
  options: GetItemsOptions,
): Promise<GetItemsResponse> => {
  const urlBase = new URL(`${API_URLS.BASE}/item/`);
  const url = makeURLWithItemFilters(urlBase, options);
  const getItemsResponse = await fetchData(url);
  return getItemsResponse;
};

export const getItemsCSV = async (
  options: GetItemsOptions,
): Promise<GetItemsCSVResponse> => {
  const urlBase = new URL(`${API_URLS.BASE}/item/`);
  const url = makeURLWithItemFilters(urlBase, { ...options, csv: true });

  const response: Response = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      ...authorizationHeaders(),
    },
  });

  let error: Error | null = null;
  if (!response.ok) {
    error = new Error(`HTTP error! status: ${response.status}`);
    redirectToLoginIfUnauthorized(response.status);
  }

  const data = await response.blob();

  const getItemsCSVResponse: GetItemsCSVResponse = {
    status: response.status,
    data,
    error,
  };

  return getItemsCSVResponse;
};

export const getItemRating = (
  shortname: string,
  itemPk: number,
): Promise<GetItemRatingResponse> => {
  const url = new URL(`${API_URLS.BASE}/item-rating/${shortname}/${itemPk}/`);
  return fetchData(url);
};
