import { authorizationHeaders } from '@/common/authorizationHeaders';
import { API_URLS } from '@/configs';
import type { ImagePayload } from '@/types';
import type { GenericResponse } from '.';
import { NOT_FOUND } from '../constants/status';

type GetItemImagesResponse = GenericResponse<ImagePayload[]>;

export const getItemImages = async (
  itemPk: number,
): Promise<GetItemImagesResponse> => {
  const url = `${API_URLS.BASE}/item/${itemPk}/images/`;

  const response: Response = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      ...authorizationHeaders(),
    },
  });

  let error: Error | null = null;
  let data: ImagePayload[] = [];

  if (!response.ok) {
    if (response.status === NOT_FOUND) {
      error = new Error(`Images for item ${itemPk} not found`);
    } else {
      error = new Error(`HTTP error! status: ${response.status}`);
    }
  } else {
    data = await response.json();
  }

  const getItemImagesResponse: GetItemImagesResponse = {
    status: response.status,
    data,
    error,
  };

  return getItemImagesResponse;
};
