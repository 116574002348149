import { makeURLWithItemFilters } from '@/common/api/getItems';
import { API_URLS } from '@/configs';
import type { TagListPayload } from '@/types';
import { type GenericResponse, type GetItemsOptions, fetchData } from '.';

export type GetTagsResponse = GenericResponse<TagListPayload>;

export const getTags = async (
  options: GetItemsOptions,
): Promise<GetTagsResponse> => {
  const urlBase = new URL(`${API_URLS.BASE}/tags/`);
  const url = makeURLWithItemFilters(urlBase, options);
  const getTagsResponse = await fetchData(url);
  return getTagsResponse;
};
