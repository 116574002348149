import { defineStore } from 'pinia';
import { getLocations } from '@/common/api/getLocations';
import { sessionCache } from '@/common/cacheService';
import { capitalize } from '@/common/capitalize';
import type {
  LocationListPayload,
  LocationPayload,
  LocationSuggestion,
  MatchedFieldType,
} from '@/types';

const SESSION_STORAGE_KEY = 'locations_cache';

interface Location {
  city: string;
  province: string;
  country: string;
}

interface LocationsState {
  locations: Location[];
  isLoading: boolean;
}

export function unifyLocations(locations: Location[]): Location[] {
  const uniqueLocations: Location[] = [];
  const locationSet = new Set<string>();

  locations.forEach((location) => {
    const city = (location.city || '').toLowerCase().trim();
    const province = (location.province || '').toLowerCase().trim();
    const country = (location.country || '').toLowerCase().trim();

    const cityProvinceCountrylocationKey = `${city}-${province}-${country}`;
    if (!locationSet.has(cityProvinceCountrylocationKey)) {
      locationSet.add(cityProvinceCountrylocationKey);
      uniqueLocations.push(location);
    }

    const provinceCountrylocationKey = `-${province}-${country}`;
    if (!locationSet.has(provinceCountrylocationKey)) {
      locationSet.add(provinceCountrylocationKey);
      const provinceCountryLocation: Location = {
        city: '',
        province: location.province,
        country: location.country,
      };
      uniqueLocations.push(provinceCountryLocation);
    }

    const countrylocationKey = `--${country}`;
    if (!locationSet.has(countrylocationKey)) {
      locationSet.add(countrylocationKey);
      const countryLocation: Location = {
        city: '',
        province: '',
        country: location.country,
      };
      uniqueLocations.push(countryLocation);
    }
  });

  return uniqueLocations;
}

async function transformSingleLocationData(
  payload: LocationPayload,
): Promise<Location> {
  return {
    city: payload.city,
    province: payload.province,
    country: payload.country,
  };
}

const transformLocationsData = async (
  data: LocationListPayload,
): Promise<Array<Location>> =>
  Promise.all(
    data.map(async (payload) => transformSingleLocationData(payload)),
  );

export const useLocationStore = defineStore('locations', {
  state: (): LocationsState => ({
    locations: [],
    isLoading: false,
  }),
  actions: {
    async fetchLocations() {
      this.isLoading = true;

      const cachedLocations =
        sessionCache.getItem<Location[]>(SESSION_STORAGE_KEY);
      if (cachedLocations && cachedLocations.length > 0) {
        this.locations = cachedLocations;
        console.debug(
          `[stores][locations] Loaded ${this.locations.length} locations from session storage`,
        );
        this.isLoading = false;
        return;
      }

      try {
        const { status, data } = await getLocations();
        if (status !== 200 || !data) {
          throw new Error('Failed to load locations');
        }
        const locations = await transformLocationsData(data);
        this.locations = unifyLocations(locations);
        console.debug(
          `[stores][locations] Loaded ${this.locations.length} locations`,
        );

        sessionCache.setItem(SESSION_STORAGE_KEY, this.locations);
      } catch (e: unknown) {
        console.debug(`Unable to fetch locations: ${e}`);
      } finally {
        this.isLoading = false;
      }
    },
    getSuggestions(prefix: string | null): LocationSuggestion[] {
      if (!prefix) return [];

      // Helper function to normalize strings: remove accents and convert to lowercase
      const normalize = (str: string) =>
        str
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase();

      const prefixWords = normalize(prefix)
        .toLowerCase()
        .split(',')
        .map((word) => word.trim())
        .filter((word) => word.length > 0);

      if (prefixWords.length === 0) {
        return [];
      }

      // Filter locations based on matching all prefix words and store matched fields
      const matchesPrefix = (
        location: Location,
      ): { matches: boolean; matchedFields: MatchedFieldType[] } => {
        const normalizedLocation = {
          country: normalize(location.country),
          province: normalize(location.province || ''),
          city: normalize(location.city || ''),
        };

        // Track matched fields
        // TODO search for all matches, with all permutations of prefixes.
        const matchedFields: MatchedFieldType[] = [];

        prefixWords.forEach((prefixWord) => {
          if (
            !matchedFields.includes('country') &&
            normalizedLocation.country.includes(prefixWord)
          ) {
            matchedFields.push('country');
          } else if (
            !matchedFields.includes('province') &&
            normalizedLocation.province.includes(prefixWord)
          ) {
            matchedFields.push('province');
          } else if (
            !matchedFields.includes('city') &&
            normalizedLocation.city.includes(prefixWord)
          ) {
            matchedFields.push('city');
          }
        });

        return {
          matches: matchedFields.length === prefixWords.length,
          matchedFields,
        };
      };

      // Filter locations and map them to LocationSuggestion
      const matchingLocations = this.locations
        .map((location) => {
          const { matches, matchedFields } = matchesPrefix(location);
          if (!matches) return null;

          // Determine locationType for sorting
          let locationType = 4; // default to 4 (city, province, country)
          const hasCountry = !!location.country;
          const hasProvince = !!location.province;
          const hasCity = !!location.city;

          if (hasCountry && !hasProvince && !hasCity) {
            locationType = 1; // country only
          } else if (hasCountry && hasProvince && !hasCity) {
            locationType = 2; // province and country
          } else if (hasCountry && hasCity && !hasProvince) {
            locationType = 3; // city and country
          } else if (hasCountry && hasProvince && hasCity) {
            locationType = 4; // city, province, country
          }

          return {
            country: capitalize(location.country),
            province: capitalize(location.province),
            city: capitalize(location.city),
            matchedFields,
            locationType,
          };
        })
        .filter(
          (location): location is LocationSuggestion => location !== null,
        );

      // Sort locations based on locationType and then alphabetically
      const sortedLocations = matchingLocations.sort((a, b) => {
        // First sort by locationType
        if (a.locationType !== b.locationType) {
          return a.locationType - b.locationType;
        }
        // If same type, sort alphabetically
        const locationA = `${normalize(a.country)}, ${normalize(a.province)}, ${normalize(a.city)}`;
        const locationB = `${normalize(b.country)}, ${normalize(b.province)}, ${normalize(b.city)}`;
        return locationA.localeCompare(locationB);
      });

      // Limit to top 5 suggestions
      return sortedLocations.slice(0, 5);
    },
  },
});
