<script setup lang="ts">
import { type Ref, computed, ref } from 'vue';
import { FhIcon } from '@fareharbor-com/beacon-vue';
import { copy } from '@fareharbor-com/beacon-vue/icons';
import { Tooltip } from 'flowbite';
import { uniqueId } from '@/common/uniqueId';

const MSG_DEFAULT = 'Copy to clipboard';
const MSG_COPIED = 'Copied!';

interface InfoCopyProps {
  information: string;
}

const props = defineProps<InfoCopyProps>();

const currentComponentID = computed(() => uniqueId('tooltip-info-copy'));

const tooltipText: Ref<string> = ref(MSG_DEFAULT);
const isCopied: Ref<boolean> = ref(false);
const timeoutID: Ref<number | null> = ref(null);

function getTooltip(): Tooltip {
  // @ts-ignore
  return FlowbiteInstances.getInstance('Tooltip', currentComponentID.value);
}

function cancelCoolOff() {
  if (timeoutID.value !== null) {
    window.clearTimeout(timeoutID.value);
    timeoutID.value = null;
  }
}

function doCopy(event: MouseEvent) {
  event.preventDefault();
  event.stopPropagation();

  isCopied.value = true;
  navigator.clipboard.writeText(props.information);
  const tooltip: Tooltip = getTooltip();
  tooltip.destroy();
  tooltipText.value = MSG_COPIED;
  tooltip.init();
  tooltip.show();

  cancelCoolOff();
  timeoutID.value = window.setTimeout(() => {
    tooltip.hide();
  }, 1000);
}

function onMouseEnter() {
  const tooltip: Tooltip = getTooltip();

  if (isCopied.value) {
    cancelCoolOff();
    isCopied.value = false;
    tooltip.destroy();
    tooltipText.value = MSG_DEFAULT;
  }

  tooltip.init();
  tooltip.show();
}

function onMouseLeave() {
  if (isCopied.value) return;
  const tooltip: Tooltip = getTooltip();
  tooltip.hide();
}
</script>

<template>
  <div class="focus:outline-none">
    <FhIcon
      class="h-4 cursor-pointer focus:outline-none"
      :data-tooltip-target="currentComponentID"
      data-tooltip-placement="bottom"
      data-tooltip-trigger="none"
      @mouseenter="onMouseEnter"
      @mouseleave="onMouseLeave"
      @click="doCopy"
      :icon="copy"
      size="sm"
    />

    <div
      :id="currentComponentID"
      role="tooltip"
      class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
    >
      {{ tooltipText }}
      <div class="tooltip-arrow" data-popper-arrow></div>
    </div>
  </div>
</template>
