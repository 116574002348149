import { defineStore } from 'pinia';
import { Logger } from '@/common/Logger';
import { getUser } from '@/common/api/getUser';
import { useToastStore } from '@/stores/useToastStore';
import type { MarketplaceUser, MarketplaceUserPayload } from '@/types';

export async function transformUserData(
  userPayload: MarketplaceUserPayload,
): Promise<MarketplaceUser> {
  try {
    const user = {
      user: {
        id: userPayload.user.id,
        username: userPayload.user.username,
        email: userPayload.user.email,
        firstName: userPayload.user.first_name,
        lastName: userPayload.user.last_name,
        isStaff: userPayload.user.is_staff,
        isActive: userPayload.user.is_active,
      },
      company: {
        id: userPayload.company.id,
        name: userPayload.company.name,
        shortName: userPayload.company.shortname,
        companyUrl: userPayload.company.company_url,
        currency: userPayload.company.currency,
      },
      createdAt: userPayload.created_at,
      modifiedAt: userPayload.modified_at,
      inFhdn: userPayload.in_fhdn,
      inFhdnApi: userPayload.in_fhdn_api,
    };
    return user;
  } catch (error) {
    console.debug('Error transforming user payload:', error);
    throw error;
  }
}

export const useUserStore = defineStore('user', {
  state: () => ({
    user: null as MarketplaceUser | null,
  }),

  actions: {
    async fetchUserData() {
      const toastStore = useToastStore();
      try {
        const response = await getUser();
        if (response.status !== 200) {
          toastStore.error('Failed to fetch user data. Please try again.');
          return;
        }
        if (!response.data) {
          toastStore.error(
            // eslint-disable-next-line max-len
            'Oops! Something went wrong while fetching your profile. Please log out and log back in, or reach out to our support team for assistance.',
          );
          return;
        }
        this.user = await transformUserData(response.data);
        toastStore.success('Login complete—enjoy your session!');
      } catch (error) {
        Logger.error(error);
        toastStore.error('An error occurred while fetching user data.');
      }
    },
    clearUserData() {
      this.user = null;
    },
  },
});
