<script setup lang="ts">
import { onMounted } from 'vue';
import { RouterView } from 'vue-router';
import '@fareharbor-com/beacon-vue/fonts/roboto';
import {
  dashboardLightTheme,
  useTheme,
} from '@fareharbor-com/beacon-vue/themes';
import { initFlowbite } from 'flowbite';
import MainFooter from '@/components/MainFooter.vue';
import MainHeader from '@/components/MainHeader.vue';
import ToastContainer from '@/components/ToastContainer.vue';
import BaseFlexbox from '@/components/ui/BaseFlexbox/BaseFlexbox.vue';

useTheme(dashboardLightTheme);

onMounted(() => {
  initFlowbite();
});

function throwError() {
  throw new Error('Sentry Error');
}
</script>

<template>
  <BaseFlexbox
    direction="column"
    class="min-h-screen"
    gap="0"
    justifyContent="normal"
    alignItems="stretch"
  >
    <MainHeader />
    <RouterView :key="$route.fullPath" />
    <MainFooter />
    <button class="hidden" @click="throwError">Throw error</button>
  </BaseFlexbox>
  <ToastContainer />
</template>
