import type { ErrorResponse, ValidationErrorResponse } from '@/types';

const UNKNOWN_ERROR_MESSAGE = 'An unexpected error occurred.';

type ErrorHandlerFn = (
  error: ErrorResponse | ValidationErrorResponse,
) => string[];

/**
 * Handlers for different HTTP status codes.
 * Each handler processes the error object and returns an array of error messages.
 */
export const errorHandlersByStatusNumber: Record<number, ErrorHandlerFn> = {
  400: (error: ValidationErrorResponse) => {
    const errors: string[] = [];
    if (error.message) {
      errors.push(error.message);
    }
    (error.detail ?? []).forEach((detail: { message: string }) => {
      errors.push(detail.message);
    });
    return errors;
  },
  401: (error: ErrorResponse) => {
    const errors: string[] = [];

    errors.push(error.message);
    return errors;
  },
};

/**
 * Parses the error response based on the HTTP status code.
 * If a handler for the status code is not found or the error is undefined,
 * it returns a generic unknown error message.
 */
export const formErrorParser = (
  status: number,
  error?: ErrorResponse | ValidationErrorResponse,
): string[] => {
  const handler = errorHandlersByStatusNumber[status];
  if (!error || !handler) {
    return [UNKNOWN_ERROR_MESSAGE];
  }
  return handler(error);
};
