<script setup lang="ts">
import { computed } from 'vue';
import { FhIcon } from '@fareharbor-com/beacon-vue';
import { photos } from '@fareharbor-com/beacon-vue/icons';
import InfoCopy from '@/components/InfoCopy.vue';
import BaseFlexbox from '@/components/ui/BaseFlexbox/BaseFlexbox.vue';

interface ItemListDetailsProps {
  row: any;
}

const props = defineProps<ItemListDetailsProps>();

const imageUrl = computed(() => {
  if (props.row.imageUrl !== '') {
    return props.row.imageUrl;
  }

  return null;
});
</script>

<template>
  <BaseFlexbox class="px-1 py-3 w-80" alignItems="center">
    <div class="basis-16 w-16 md:basis-16 md:w-16 sm:basis-16 sm:w-16 relative">
      <BaseFlexbox
        v-if="imageUrl !== null"
        class="aspect-square w-16"
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <img
          class="aspect-square rounded-md w-full"
          :src="imageUrl"
          alt="row.name"
        />
      </BaseFlexbox>

      <BaseFlexbox
        v-else
        class="aspect-square rounded-md bg-gray-100 w-16"
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <FhIcon :icon="photos" color="disabled" size="lg" />
      </BaseFlexbox>
      <div
        class="rounded-lg absolute left-0 top-0 z-10 h-full w-full bg-gradient-to-t from-black via-transparent to-transparent"
        v-if="imageUrl"
      >
        <span
          v-if="row.extraImagesCount > 0"
          class="text-white font-bold absolute bottom-0 right-1"
        >
          +{{ row.extraImagesCount }}
        </span>
      </div>
    </div>

    <BaseFlexbox direction="column" class="pl-4 px-2 grow-1">
      <span class="font-medium">{{ row.name }}</span>
      <BaseFlexbox alignItems="center">
        <span class="text-slate-500 mr-1">ID: {{ row.itemId }}</span>
        <InfoCopy
          class="text-slate-400 font-normal"
          :information="row.itemId.toString()"
        />
      </BaseFlexbox>
    </BaseFlexbox>
  </BaseFlexbox>
</template>
