// TODO: add back when needed
// import ItemListDetailsGo from '@/components/itemList/ItemListDetailsGo.vue';
import ItemListDetails from '@/components/itemList/ItemListDetails.vue';
import ItemListRating from '@/components/itemList/ItemListRating.vue';
import ItemListTags from '@/components/itemList/ItemListTags.vue';
import type { ColumnConfig } from '@/components/shared/DeskTable/types';
import ItemListBookmark from './ItemListBookmark.vue';
import ItemListCompany from './ItemListCompany.vue';
import ItemListLocation from './ItemListLocation.vue';
import ItemListQualityScore from './ItemListQualityScore.vue';
import type { ItemListColumnKey } from './types';

export const itemListColumnConfig: ColumnConfig<ItemListColumnKey>[] = [
  {
    header: 'ITEM',
    renderCell: ItemListDetails,
    key: 'entireItem',
  },
  {
    header: 'COMPANY',
    renderCell: ItemListCompany,
    key: 'entireItem',
  },
  {
    header: 'LOCATION',
    renderCell: ItemListLocation,
    key: 'entireItem',
  },

  {
    header: 'ITEM TYPE',
    renderCell: ItemListTags,
    key: 'entireItem',
  },

  {
    header: 'RATING',
    renderCell: ItemListRating,
    key: 'entireItem',
  },

  {
    header: 'QUALITY SCORE',
    renderCell: ItemListQualityScore,
    key: 'entireItem',
  },
  {
    header: 'BOOKMARK',
    renderCell: ItemListBookmark,
    key: 'entireItem',
  },
];
