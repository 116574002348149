import { useSessionStorage } from '@vueuse/core';
import { defineStore } from 'pinia';
import { config } from '@/configs';

const MIN_SEARCH_LENGTH = 0;

export const useSearchStore = defineStore('searchStore', {
  state: () => ({
    locationSearchText: config.IGNORE_SESSION_STORAGE
      ? ''
      : useSessionStorage('locationSearchText', ''),
    locationSearchChanged: config.IGNORE_SESSION_STORAGE
      ? false
      : useSessionStorage('locationSearchChanged', false),
    companyItemSearchText: config.IGNORE_SESSION_STORAGE
      ? ''
      : useSessionStorage('companyItemSearchText', ''),
    companyItemSearchChanged: config.IGNORE_SESSION_STORAGE
      ? false
      : useSessionStorage('companyItemSearchChanged', false),
  }),
  getters: {
    locationSearch: (state) =>
      state.locationSearchText.length > MIN_SEARCH_LENGTH
        ? state.locationSearchText
        : null,
    companyItemSearch: (state) =>
      state.companyItemSearchText.length > MIN_SEARCH_LENGTH
        ? state.companyItemSearchText
        : null,
  },
});
