import {
  type NavigationGuardNext,
  type RouteLocationNormalized,
  createRouter,
  createWebHistory,
} from 'vue-router';
import {
  guardBadPageQueryParam,
  guardEmptyQueryParams,
  guardUnauthenticated,
} from '@/router/guards';
import { useAuthStore } from '@/stores/useAuthStore';
import ItemDetailsView from '@/views/ItemDetailsView.vue';
import ItemListView from '@/views/ItemListView.vue';
import LoginView from '@/views/LoginView.vue';
import NotFoundView from '@/views/NotFoundView.vue';

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    beforeEnter: (
      to: RouteLocationNormalized,
      from: RouteLocationNormalized,
      next: NavigationGuardNext,
    ) => {
      const authStore = useAuthStore();
      if (authStore.isAuthenticated) {
        next({ name: 'item-list' });
      } else {
        next();
      }
    },
  },
  {
    path: '/',
    name: 'item-list',
    component: ItemListView,
    beforeEnter: [
      guardUnauthenticated,
      guardBadPageQueryParam,
      guardEmptyQueryParams,
    ],
  },
  {
    path: '/items/:itemPk',
    name: 'item-detail',
    props: true,
    component: ItemDetailsView,
    beforeEnter: guardUnauthenticated,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: NotFoundView,
    beforeEnter: guardUnauthenticated,
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

export default router;
