import DOMPurify from 'dompurify';
import { Converter } from 'showdown';
import { replaceAll } from './replaceAll';

/**
 * Converts markdown to HTML with custom escaping and unescaping.
 * @param markdown The string with markdown formatting.
 * @returns The sanitized HTML string.
 */
export function markdownToHtml(markdown: string | undefined | null): string {
  const converter = new Converter({
    simpleLineBreaks: true, // Converts single line breaks to <br>
    noHeaderId: true, // Disables automatic ID generation for headers
    openLinksInNewWindow: true, // Opens links in a new window/tab
  });

  // Escape certain characters to prevent unwanted HTML rendering
  let escapedMarkdown = replaceAll(markdown, '&', '&amp;');
  escapedMarkdown = replaceAll(escapedMarkdown, '<', '&lt;');
  escapedMarkdown = replaceAll(escapedMarkdown, '"', '&quot;');
  escapedMarkdown = replaceAll(escapedMarkdown, "'", '&#39;');

  let html = converter.makeHtml(escapedMarkdown);

  html = replaceAll(html, '&gt;', '>');

  html = replaceAll(html, '&#96;', '`');

  // Sanitize the HTML to remove any unsafe elements
  const sanitizedHtml = DOMPurify.sanitize(html);

  return sanitizedHtml;
}
