<script setup lang="ts">
import { FhButton } from '@fareharbor-com/beacon-vue';

function contactSupport() {
  window.open('https://fareharbor.com/help/submit/', '_blank');
}
</script>
<template>
  <div
    class="flex flex-col items-center justify-center h-screen bg-gray-100 px-4"
  >
    <h1
      class="text-5xl font-bold text-gray-800 mb-6"
      data-test-id="404-error-msg-title"
    >
      404 - Page Not Found
    </h1>
    <p
      class="text-lg text-gray-600 mb-4 text-center"
      data-test-id="404-error-msg-text"
    >
      Sorry about that. You may have followed a bad or outdated link. If you
      need help, please contact Support.
    </p>

    <FhButton size="lg" @click="contactSupport">Contact Support</FhButton>
  </div>
</template>
